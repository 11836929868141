import { TagProps } from 'libs/growth-platform-brand-system-v2/src/components/Tag';
import { createSchema } from 'morphism';

export const schemaTag = createSchema<TagProps, any>({
  title: 'title',
  type: 'type',
  color: 'color',
  isDark: 'isDark',
  isSquared: 'isSquared',
  size: 'size',
  isUppercase: 'isUppercase',
});
