import { createSchema } from 'morphism';

export const schemaBaseTemplate = createSchema<any, any>({
  pageId: {
    path: 'pageContext',
    fn: (pageContext: any) => {
      const pageId = pageContext?.ownerNodeId?.split('-')?.[0];
      return pageId;
    },
  },
  locale: 'pageContext.locale',
  hero: {
    path: ['hero'],
    fn: ({ hero }: any) => {
      if (hero?.[0]) return hero?.[0];
    },
  },
  heroLogos: {
    path: ['hero'],
    fn: ({ hero }: any, source: any) => {
      const logos = hero?.[0]?.heroAdditionalComponent?.logosList;
      if (logos) return logos.map((x: any) => x?.logo);
    },
  },
  stickyBanner: {
    path: ['stickyBanner'],
    fn: ({ stickyBanner }: any) => {
      if (stickyBanner)
        return stickyBanner.filter(
          (el: any) => el?.__typename === 'DatoCmsBlogBannerBlock',
        )?.[0]?.banner?.banner?.[0];
    },
  },
  accordions: {
    path: ['accordions'],
    fn: ({ accordions }: any) => {
      if (accordions?.length > 0)
        return accordions.map((accordion: any) => {
          return {
            ...accordion,
            as: 'a',
            href: `#${accordion?.surtitle?.replace(/\W+/g, '-')}`,
            picto: {
              name: accordion?.icon || 'automation',
              size: 'small',
              variant: 'light',
            },
          };
        });
    },
  },
  blocks: 'blocks',
});
